import NewPost from "./NewPost";
import Post from "./Post";

export default function Feed() {
  return (
    <>
      <NewPost />
      <Post />
      <Post />
    </>
  )
}