import { Container, Row, Col } from "react-bootstrap"
import headerImg from '../../assets/logos/BTMlogo.png'

export const Header = () => {

	return (
<section className="banner-small" id="home">
	<Container>
		<Row className="align-items-center">
			<Col xs={12} md={6} xl={5} className="mb-20" >
				<div className="animate__animated animate__zoomIn headerImg">
					<img src={headerImg} alt="Header Img" />
				</div>
			</Col>
			<Col xs={12} md={6} xl={7}>
				<div className="animate__animated animate__fadeIn">
					<h1 className="animate__animated animate__slideInUp"><span className="bold">Terms & Conditions</span></h1>
					<p><br /><br /></p>
					<p className="border-top-bottom subtitle mb-30 mt-30 animate__animated animate__slideInUp">
					Updated at 2024-08-28
					</p>
				</div>
			</Col>
		</Row>
	</Container>
</section>
	)
}