import { Button, Modal, Row, Col, Form } from 'react-bootstrap'
import { useState } from 'react'
import signinImg from '../../assets/svg/brand-logos/signin.svg'
import { useDispatch } from 'react-redux'
import { authActions } from '_store'
import { toast } from 'react-toastify'

export const SignIn = () => {

  const dispatch = useDispatch()

  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleOpenModal = () => setShowModal(true)

  const [authLoading, setAuthLoading] = useState(false)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')


  const login = (e) => {
    e.preventDefault()
    setAuthLoading(true)
    if (validate()) {
      dispatch(authActions.login({ email, password }))
    }
    setAuthLoading(false)
  }

  const validate = () => {
    let result = true;
    if (email === '' || email === null) {
      result = false;
      // console.log('email missing')
      setAuthLoading(false)
      toast.warning('Please enter email', { position: 'bottom-left' })
    }
    if (password === '' || password === null) {
      result = false;
      // console.log('password missing')
      setAuthLoading(false)
      toast.warning('Please enter Password', { position: 'bottom-left' })
    }
    return result;
  }

  return (
    <>
      <Button onClick={handleOpenModal} variant='Link'>
        <img src={signinImg} alt="SignIn Icon" />
      </Button>

      <Modal show={showModal} onHide={handleCloseModal} className='text-center' centered data-bs-theme={'dark'}>
        <Modal.Header closeButton onClick={handleCloseModal}>
          {/* <Modal.Title style={{color: 'black'}}>Sign In</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Form className='form' onSubmit={login}>
            <div className='text-center mb-10'>
              <h1 className="text-light fw-bolder mb-3">Sign In</h1>
              {/* <div className="text-gray-500 fw-semibold fs-6">Your Social Campaigns</div> */}
            </div>
            <Row className='g-3 mb-9'>
              <Col>
                {/* <Row>
                    <Link to="#" className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                      <Image alt="Logo" src="/img/svg/brand-logos/google-icon.svg" className="h-15px me-3" />Sign in with Google
                    </Link>
                  </Row>
                  <Row className="separator separator-content my-14">
                    <span className="w-125px text-gray-500 fw-semibold fs-7">Or with email</span>
                  </Row> */}
                <Row className="mb-8">
                  <Form.Control type="text" placeholder='Email' name='email' value={email} onChange={e => setEmail(e.target.value)} className='form-control bg-transparent' />
                </Row>
                <Row className='mb-10'>
                  <Form.Control type='password' placeholder='Password' name='password' value={password} onChange={e => setPassword(e.target.value)} className='form-control bg-transparent' />
                </Row>
                {/* <Row className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                      <Link to="#" className='link-primary'>Forgot Password?</Link>
                  </Row> */}
                {authLoading
                  ? <Row><Col><Button variant="primary" type="submit" disabled style={{ 'width': '300px' }}>Signing In...</Button></Col><Col><Button variant="danger" disabled style={{ 'width': '100px' }}>Cancel</Button></Col></Row>
                  : <Row><Col><Button variant="primary" type="submit" style={{ 'width': '300px' }}>Sign In</Button></Col><Col><Button variant="danger" onClick={handleCloseModal} style={{ 'width': '100px' }}>Cancel</Button></Col></Row>
                }
                {/* <Row className='text-gray-500 text-center fw-semibold fs-6'>
                      Not a member yet? &nbsp;
                      <Link to="#" className='link-primary'>Sign Up</Link>
                  </Row> */}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </>
  )
}