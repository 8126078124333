import { Card, Row, Col, Container } from "react-bootstrap";


export default function Avatar() {
  return (
    <Card data-bs-theme={'dark'} className="mt-3 mb-3">
      <Container>
        <Row className="mt-5 mb-5">
          <Col>Avatar</Col>
        </Row>
      </Container>
    </Card>
  )
}