import { Card, Col, Row, Modal, Button } from 'react-bootstrap'
import { useState } from 'react'
import infoImage from '../../../assets/backgrounds/goldenCardContests.png'

export const Contests = () => {
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  return (
    <Col xs={12} md={6} xl={4}>
      <Card className="info-cardbx text-center">
        <Card.Img variant="top" src={infoImage} alt="Contests" />
        <div className='info-txtx'>
          <h4>Contests</h4>
          <span>Practice what you have learned</span><br /><br />
          <Button onClick={handleShowModal}>Read more</Button>
        </div>
      </Card>
      <Modal show={showModal} onHide={handleCloseModal} data-bs-theme="dark" centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Contests</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='mt-3 mb-3'>
            <Col>
              <h2 className='mt-3 mb-3'>Contests</h2>
              <p>
                Speech contests are a Toastmasters tradition. Each year, thousands of Toastmasters compete in the Humorous, Evaluation, Tall Tales, Table Topics and International speech contests. Competition begins with club contests, and winners continue competing through the Area, Division and District levels. Winners of the District level International Speech Contest proceed to the region quarterfinal level. Following region quarterfinals, winners advance to the semifinals for a chance to take part in the World Championship of Public Speaking®.
                <br />
                In order to participate to these contests, there is a set of rules.
              </p>
              <h2 className='mt-3 mb-3'>Humorous speaches and Table Topics contest</h2>
              <p>For this contest, eligibility is a little less restrictive where the rules state that a contestant can be eligible if the person is:</p>
              <ul>
                <li>a paid member of an eligible club of Toastmasters.</li>
                <li>is not part of District officers whose terms expire Jun 30th or candidates for elected positions for the term beginning in the upcoming July 1st.</li>
              </ul>
              <h2 className='mt-3 mb-3'>International Speeches and Evaluations contest</h2>
              <p>For this contest eligibility is a bit more restrictive where the rules state that a contestant can be eligible if the person is:</p>
              <ul>
                <li>be a paid member of a club in the Area, Division, and District in which they are competing.</li>
                <li>have earned certificates of completion in Levels 1 and 2 in any path in the Toastmasters Pathways learning experience or earned a Distinguished Toastmaster award.</li>
                <li>not be part of District officers whose terms expire June 30 or candidates for elected positions for the term beginning in the upcoming July 1.</li>
              </ul>
              <h2 className='mt-3 mb-3'>Judging</h2>
              <p>
                To be a chief judge, voting judge or tiebreaking judge at a Toastmasters speech contest you must meet all eligibility requirements identified below.
              </p>
              <ol>
                <li>At a club contest, be a paid member.</li>
                <li>At an Area, Division or District Contest:
                  <ul>
                    <li>be a paid member for a minimum of six (6) months</li>
                    <li>have completed a minimum of six (6) speech projects or earned certificates of completion in Levels 1 and 2 of any path in the Toastmasters Pathways learning experience.</li>
                  </ul>
                </li>
              </ol>
              <p>
                All other contest officials and the Evaluation Contest test speaker must be paid members.
              </p>
              <Button className='learn-more' href='https://www.toastmasters.org/Resources/Speech%20Contests' target='_blank' rel="noreferrer">Learn More</Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Col>
  )
}