import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useSelector } from "react-redux"
import { Spinner } from 'react-bootstrap'

import { useEffect, useState } from 'react'

export default function AgendaItemEditModal({ agendaItem, getAgenda }) {

    const { user: authUser } = useSelector(x => x.auth)

    const url = process.env.REACT_APP_URL

    const [startTime, setStartTime] = useState('')
    const [timeGreen, setTimeGreen] = useState('')
    const [timeAmber, setTimeAmber] = useState('')
    const [timeRed, setTimeRed] = useState('')
    const [totalTime, setTotalTime] = useState('')
    const [role, setRole] = useState('')
    const [presenter, setPresenter] = useState('')
    const [event, setEvent] = useState('')
    const [eventDescription, setEventDescription] = useState('')
    const [position, setPosition] = useState('')

    const [saveLoading, setSaveLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => setShowModal(false)
    const handleShowModal = () => setShowModal(true)

    useEffect(function () {
        if (!agendaItem.startTime) {
            setStartTime('')
        }
        else { setStartTime(agendaItem.startTime) }
        if (!agendaItem.timeGreen) {
            setTimeGreen('')
        }
        else { setTimeGreen(agendaItem.timeGreen) }
        if (!agendaItem.timeAmber) {
            setTimeAmber('')
        }
        else { setTimeAmber(agendaItem.timeAmber) }
        if (!agendaItem.timeRed) {
            setTimeRed('')
        }
        else { setTimeRed(agendaItem.timeRed) }
        if (!agendaItem.totalTime) {
            setTotalTime('')
        }
        else { setTotalTime(agendaItem.totalTime) }
        if (!agendaItem.role) {
            setRole('')
        }
        else { setRole(agendaItem.role) }
        if (!agendaItem.presenter) {
            setPresenter('')
        }
        else { setPresenter(agendaItem.presenter) }
        if (!agendaItem.event) {
            setEvent('')
        }
        else { setEvent(agendaItem.event) }
        if (!agendaItem.eventDescription) {
            setEventDescription('')
        }
        else { setEventDescription(agendaItem.eventDescription) }
        if (!agendaItem.position) {
            setPosition('')
        }
        else { setPosition(agendaItem.position) }
    }, [agendaItem.startTime, agendaItem.timeGreen, agendaItem.timeAmber, agendaItem.timeRed, agendaItem.totalTime, agendaItem.role, agendaItem.presenter, agendaItem.event, agendaItem.eventDescription, agendaItem.position])

    const handleSubmit = (e) => {
        e.preventDefault()
        setSaveLoading(true)
        const validate = true;
        if (validate === true) {
            fetch(url + '/agendaItem/' + agendaItem._id, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authUser.token
                },
                body: JSON.stringify({
                    startTime: startTime,
                    timeGreen: timeGreen,
                    timeAmber: timeAmber,
                    timeRed: timeRed,
                    totalTime: totalTime,
                    role: role,
                    presenter: presenter,
                    event: event,
                    eventDescription: eventDescription,
                    position: position
                })
            })
                .then(res => {
                    if (res.status !== 200 && res.status !== 201) {
                        throw new Error('(' + res.status + ') We could not save your new agenda Item')
                    }
                    return res.json()
                })
                .then(resData => {
                    // console.log("response JSON: "+ resData)
                    toast.success('Your AgendaItem ' + event + ' has been updated', { position: 'bottom-left' })
                    setSaveLoading(false)
                    getAgenda(true)

                    handleCloseModal()
                })
                .catch(err => {
                    toast.error('' + err, { position: 'bottom-left' })
                    setSaveLoading(false)
                })
        }

    }

    return (
        <>
            <Dropdown.Item onClick={handleShowModal}>Edit Agenda Item</Dropdown.Item>
            {/* <Button onClick={handleShowModal} variant='info' className='float-end'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="14px" width="14px"><path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z" /></svg>
            </Button> */}
            <Modal centered show={showModal} onHide={handleCloseModal} className='text-center' data-bs-theme={'dark'}>

                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Agenda Item
                    </Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Time: HH:mm'
                                aria-label='Start Time'
                                name='StartTime'
                                value={startTime}
                                onChange={e => setStartTime(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Role'
                                aria-label='Role'
                                name='role'
                                value={role}
                                onChange={e => setRole(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Presenter'
                                aria-label='Presenter'
                                name='presenter'
                                value={presenter}
                                onChange={e => setPresenter(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Event'
                                aria-label='Event'
                                name='event'
                                value={event}
                                onChange={e => setEvent(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control as="textarea"
                                className='formInput'
                                placeholder='Event Description'
                                aria-label='Event Description'
                                name='eventDescription'
                                value={eventDescription}
                                onChange={e => setEventDescription(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Green: mm:ss'
                                aria-label='Time Green'
                                name='timeGreen'
                                value={timeGreen}
                                onChange={e => setTimeGreen(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Amber: mm:ss'
                                aria-label='time Amber'
                                name='timeAmber'
                                value={timeAmber}
                                onChange={e => setTimeAmber(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Red: mm:ss'
                                aria-label='Time Red'
                                name='timeRed'
                                value={timeRed}
                                onChange={e => setTimeRed(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Total Time: mm:ss *'
                                aria-label='Total Time *'
                                name='totalTime'
                                value={totalTime}
                                onChange={e => setTotalTime(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Position'
                                aria-label='Position'
                                name='position'
                                value={position}
                                onChange={e => setPosition(e.target.value)}
                            />
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        {saveLoading
                            ?
                            <>
                                <Spinner animation='border' variant='danger' />
                                Editing Agenda Item ...
                            </>
                            :
                            <>
                                <Button variant="success" type='submit' onClick={handleSubmit}>
                                    Save Agenda Item
                                </Button>
                                <Button variant="danger" onClick={handleCloseModal}>
                                    Cancel
                                </Button>
                            </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}