import { Card, Row, Col, Container, Form, Button, } from "react-bootstrap";


export default function DeleteAccount() {
  return (
    <Card data-bs-theme={'dark'} className="mt-3 mb-3">
      <Card.Header>
        <Row className="mt-3 mb-3"><Col>Deactivate account</Col></Row>
      </Card.Header>
      <Form>
        <Container>
          <Row className="mt-5 mb-5">
            <Col>
              <Form.Check //prettier ignore
                type='checkbox'
                label='I confirm my account deactivation'
              />
            </Col>
          </Row>
        </Container>
      </Form>
      <Card.Footer>
        <Row className="mt-3 mb-3">
          <Col className="text-end"><Button variant="danger">Deactivate Account</Button></Col>
        </Row>
      </Card.Footer>
    </Card>
  )
}