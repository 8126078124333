// import doubleCard from '../assets/transparentDoubleCard.png'
import { Row, Col, Card, Container } from "react-bootstrap";

export const ForYou = () => {

  return (
    <section className="foryou" id="foryou">
      <Container>
        <Row className="text-center">
          <h2>For you</h2>
          <p><br /></p>
        </Row>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={4}>
            <Card className="text-center">
              <h4>Improve your public speaking skills</h4>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" height="24"><path fill="#0da3e2" d="M429.6 92.1c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8H224V432c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352z"></path></svg>
              <p>
                Being afraid of public speaking is a common thing for many people. Brno Toastmasters is the perfect environment to learn to overcome this fear and become a great public speaker
              </p>
            </Card>

          </Col>
          <Col xs={12} md={6} xl={4}>
            <Card className="text-center">
              <h4>Learn through feedback</h4>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" height="24"><path fill="#db3a1b" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg>
              <p>
                Every speech can be improved only by receiving honest and correct feedback. And even feedback is, at the end of the day, still public speaking
              </p>
            </Card>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Card className="text-center">
              <h4>Build your network</h4>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24"><path fill="#35aa47" d="M439 7c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H296c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39L439 7zM72 272H216c13.3 0 24 10.7 24 24V440c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39L73 505c-9.4 9.4-24.6 9.4-33.9 0L7 473c-9.4-9.4-9.4-24.6 0-33.9l87-87L55 313c-6.9-6.9-8.9-17.2-5.2-26.2s12.5-14.8 22.2-14.8z"></path></svg>
              <p>
                With Brno Toastmasters you can become part of a group of people that are thriving in international contests and connect even further than the club itself
              </p>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  )
}