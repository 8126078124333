import { NavBar } from "../components/general/NavBar"
import { Footer } from "../components/general/Footer"
import { Container } from "react-bootstrap"
import Header from "components/Profile/Header"
import Menu from "components/Profile/Menu"
import { useSelector } from "react-redux"
import NotAuthorized from "./NotAuthorized"

export default function Profile() {

  const { user: authUser } = useSelector(x => x.auth)

  if (!authUser) {
    return <NotAuthorized />
  }
  else {
    return (
      <>
        <NavBar pagename='404' />
        <section className="profile">
          <Container>
            <Header />
            <Menu />
          </Container>
        </section>
        <Footer />
      </>
    )
  }
}