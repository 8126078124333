import { Row, Col, Dropdown, Card, Spinner } from 'react-bootstrap'
import { useSelector } from "react-redux"
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchWrapper } from '_helpers/fetch-wrapper'

import navIcon2 from "../../assets/img/nav-icon2.svg";
import youTube from '../../assets/svg/brand-logos/icons8-youtube-logo-100.svg';

import MeetingEditModal from '../Meetings/crudModals/MeetingEditModal'
import MeetingDeleteModal from '../Meetings/crudModals/MeetingDeleteModal'

export default function MeetingCard() {

  const url = process.env.REACT_APP_URL
  const noBgImage = process.env.REACT_APP_NO_BG_IMAGE

  const { user: authUser } = useSelector(x => x.auth)

  const [isLoading, setIsLoading] = useState(true, [])
  const [meeting, setMeeting] = useState([])
  const params = useParams()
  const meetingId = params.meetingId

  const getMeeting = async () => {
    setIsLoading(true)
    const response = await fetchWrapper.get(`${url}/meeting/${meetingId}`)
    setMeeting(response)
    setIsLoading(false)
  }


  useEffect(function () {
    getMeeting()
    // eslint-disable-next-line
  }, [])

  if (isLoading) {
    return (<Spinner animation='border' variant='danger' />)
  }
  else {
    const backgroundImage = (meeting.imageUrl === '' || meeting.imageUrl === 'undefined' || !meeting.imageUrl) ? noBgImage : meeting.imageUrl
    const meetingTitle = (meeting.imageUrl === '' || meeting.imageUrl === 'undefined' || !meeting.imageUrl) ? meeting.meetingTheme : ''

    return (
      <section>
        <Row>
          <Col>
            <Card className='my-2 text-center semi-transparent text-white bg-dark' >
              <Card.Title className='my-2'>
                <Row>
                  <Col style={{ height: '21px' }}></Col>
                  <Col xs={2} lg={2} >
                    {authUser &&
                      <Dropdown drop='start' data-bs-theme="dark" className='float-end'>
                        <Dropdown.Toggle variant="dark">
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis-vertical" className="svg-inline--fa fa-ellipsis-vertical " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" width="6" height="16"><path fill="currentColor" d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"></path></svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <MeetingEditModal meeting={meeting} getMeeting={getMeeting} />
                          <Dropdown.Divider />
                          <MeetingDeleteModal meeting={meeting} getMeeting={getMeeting} />
                        </Dropdown.Menu>
                      </Dropdown>
                    }
                  </Col>
                </Row>
              </Card.Title>
              <Card.Body className='to-left'>
                <Row>
                  <Col xs={12} lg={12}>
                    <Card.Img variant="left" src={backgroundImage} alt='Meeting ' style={{ marginTop: "-42px" }} />
                    {/* <h3 className='not-found-title'>{meetingTitle}</h3> */}
                  </Col>
                </Row>
                <Row>
                  <Col className='mt-5'>
                    <h3>{meetingTitle}</h3>
                    <p>{meeting.meetingType} Meeting <span>{(meeting.meetingNumber !== null) && '#' + meeting.meetingNumber}</span></p>
                    <p>{meeting.meetingDate}</p>
                    <h5>{meeting.meetingDescription !== '' && <>{meeting.meetingDescription}</>} </h5>
                    <br />
                  </Col>
                </Row>
                <Row className='to-left'>
                  <Col className='social-icon mt-3'>
                    {(meeting.facebookUrl !== '' && meeting.facebookUrl) ? <a href={meeting.facebookUrl} target='_blank' rel="noreferrer"><img src={navIcon2} alt="Facebook Icon Event" /></a> : ''}
                    {(meeting.youtubeUrl !== '' && meeting.youtubeUrl) ? <a href={meeting.youtubeUrl} target='_blank' rel="noreferrer"><img src={youTube} alt="Youtube Icon Event" /></a> : ''}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    )
  }
}