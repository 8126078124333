import Modal from 'react-bootstrap/Modal'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCircleInfo } from "@fortawesome/free-solid-svg-icons"
import Button from 'react-bootstrap/Button'

import { useState } from 'react'

export default function MeetingInfoModal({ meeting }) {
    const [showDetails, setShowDetails] = useState(false)
    const handleCloseDetails = () => setShowDetails(false)
    const handleShowDetails = () => setShowDetails(true)
    console.log()

    return (
        <>
            <Button variant='link' onClick={handleShowDetails} style={{ height: '20px' }}>
                {/* <FontAwesomeIcon icon={faCircleInfo} style={{height: '15px'}}/> */}
            </Button>
            <Modal show={showDetails} onHide={handleCloseDetails} className='text-center' data-bs-theme={'dark'}>
                <Modal.Header closeButton >
                    <Modal.Title>{meeting.meetingTheme}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {meeting.meetingDescription}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDetails}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

