import { Card, Col, Row, Modal, Button, Image } from 'react-bootstrap'
import { useState } from 'react'

import infoImage from '../../../assets/backgrounds/goldenCardMembership.png'
import fioQRImage from '../../../assets/FioQRMembership.png'


export const Membership = () => {
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  return (
    <Col xs={12} md={6} xl={4}>
      <Card className="info-cardbx text-center" variant="dark">
        <Card.Img variant="top" src={infoImage} alt="Membership Card" />
        <div className='info-txtx'>
          <h4>Membership</h4>
          <span>Responsibilities and benefits</span><br /><br />
          <Button onClick={handleShowModal}>Read more</Button>

        </div>
      </Card>
      <Modal show={showModal} onHide={handleCloseModal} data-bs-theme="dark" centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Membership</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='mt-3 mb-3'>
            <Col >
              <h2>Guest</h2>
              <p>Toastmasters clubs are welcoming guests to attend Toastmasters meetings and events for free. Although the guests would be limited from having access to complete education packages, or leadership positions, we offer them a chance at every regular meeting to practice during Table Topics sessions (impromptu speeches) as well as taking some small roles during the meetings. For more details, feel free to discuss it with the Vice President for Education.</p>
            </Col>
          </Row>
          <Row className='mt-3 mb-3'>
            <Col>
              <h2>Membership</h2>
              <p>Joining Toastmasters is easy and affordable. When you become a member, you'll have the support of our club, district and the global organization as you work toward your goals. By becoming a member you're opening a door towards professional public speaking, towards successful leadership and towards a world of fascinating stories, great lessons and fantastic personal growth.</p>
              <a href='https://www.toastmasters.org/membership/why-toastmasters' target='_blank' rel="noreferrer" className="services-link">Learn More</a>
            </Col>
          </Row>
          <Row className='mt-3 mb-3'>
            <Col>
              <h2>Payment details for Membership</h2>
              <p>
                Payments for membership are to be made via bank transfer to account 2600101541 / 2010 (FIO BANKA).
                <br />
                Regular payments are made every March and September of the year.
              </p>
              <p>
                <span style={{ backgroundColor: "#E84D1C" }}>Important note for new members who want to join:</span> in between those regular payments, a prospective new member must contact the Treasurer and the Vice President for Membership of the club to calculate the correct amount to be paid based on the time left until next regular payment schedule.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-md-center mt-3 mb-3">
            <Col xs={12} sm={4} md={4}>
              <Image className="mx-auto" src={fioQRImage} width={200} />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Col>
  )
}