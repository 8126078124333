import { Card, Row, Col, Form, Container, Button } from "react-bootstrap";

export default function Notifications() {
  return (
    <Card data-bs-theme={'dark'} className="mt-3 mb-3">
      <Form>
        <Card.Header>
          <Row className="mt-3 mb-3"><Col>Notifications</Col></Row>
        </Card.Header>
        <Card.Body>
          <Container>
            <Row>
              <Col>
                <p>Notify me for the following:</p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Row className="mt-3 mb-3">
                  <Col xs={8} lg={10}>
                    <Form.Check // prettier-ignore
                      type="switch"
                      label="Next Meeting"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={8} lg={10}>
                    <Form.Check // prettier-ignore
                      type="switch"
                      label="Meeting Role Assignment"
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={8} lg={10}>
                    <Form.Check // prettier-ignore
                      type="switch"
                      label="Newsletter"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Card.Body>
        <Card.Footer>
          <Row className="mt-3 mb-3">
            <Col className="text-end"><Button variant="primary">Save Changes</Button></Col>
          </Row>
        </Card.Footer>
      </Form>
    </Card >
  )
}