import { NavBar } from "../components/general/NavBar"
// import { useEffect, useState } from 'react'
// import { Spinner } from 'react-bootstrap'
import MeetingsComponent from '../components/Meetings/MeetingsComponent'
import { Footer } from "components/general/Footer"

export default function Meetings() {

  return (
    <>
      <NavBar pagename="Meetings" />
      <MeetingsComponent />
      <Footer />
    </>
  )
}