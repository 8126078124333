import { Container, Row } from 'react-bootstrap'

import { Membership } from './info/Membership'
import { Education } from './info/Education'
import { IceBreaker } from './info/IceBreaker'
import { MeetingRoles } from './info/MeetingRoles'
import { Cot } from './info/Cot'
import { Contests } from './info/Contests'
import { AboutUs } from './info/AboutUs'
import { AboutTM } from './info/AboutTM'
import { Webtools } from './info/Webtools'

export const Info = () => {

  return (
    <section className='info' id="info">
      <Container>
        <Row className="text-center">
          <h2 className='mb-5'>Useful Info</h2>

        </Row>
        <Row>
          <Membership />
          <Education />
          <IceBreaker />
          <MeetingRoles />
          <Cot />
          <Contests />
          <AboutUs />
          <AboutTM />
          <Webtools />

        </Row>
      </Container>
    </section>
  )
}