import { NavBar } from "components/general/NavBar"
import { Footer } from "components/general/Footer"
import MeetingComponent from "components/Meeting/MeetingComponent"
import AgendaComponent from "components/Meeting/AgendaComponent"
import { Row, Col, Container } from 'react-bootstrap'
// import Assignments from "components/Meeting/Assignments"



export default function Meeting() {

  // console.log(meeting.length)

  return (
    <>
      <NavBar pagename="Meetings" />
      <section className="meeting">
        <Container>
          <Row>
            <Col xs={12} lg={4}>
              <MeetingComponent />
              {/* <Assignments /> */}
            </Col>
            <Col xs={12} lg={8}>
              <AgendaComponent />
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  )
}