
import { CountdownCircleTimer } from "react-countdown-circle-timer"
import { useEffect, useState } from "react"
import { Col, Row, Button } from "react-bootstrap"


const formatRemainingTime = (time) => {
  const minutes = Math.floor((time % 3600) / 60);
  let seconds = time % 60;
  if (seconds < 10 && seconds > -1) {
    seconds = `0${seconds}`;
  }

  return `${minutes}:${seconds}`;
};

const renderTime = ({ remainingTime }) => {
  if (remainingTime === 0) {
    return <div className="timer">Time Out</div>;
  }

  return (
    <div className="timer">
      <div className="text">Remaining</div>
      <div className="value">{formatRemainingTime(remainingTime)}</div>
    </div>
  );
};


export default function Timer(item) {
  const agendaItem = item.item.item
  const [totalDuration, setTotalDuration] = useState(parseInt(agendaItem.timeRed.split(":")[0]) * 60 + parseInt(agendaItem.timeRed.split(":")[1]) + 30)
  const [key, setKey] = useState(false)
  const [timerOn, setTimerOn] = useState(false)

  useEffect(() => {
    let myDuration = 0
    try {
      myDuration = parseInt(agendaItem.timeRed.split(':')[0]) * 60 + parseInt(agendaItem.timeRed.split(':')[1]) + 30
      // console.log(myDuration)
    }
    catch (e) {
      console.log(e.message)
    }

    setKey(prevKey => prevKey + 1)
    setTotalDuration(myDuration)
  }, [agendaItem.timeRed])

  const startHandler = () => {
    setTimerOn(true)
  }

  const pauseHandler = () => {
    setTimerOn(false)
  }

  const stopHandler = () => {
    setTimerOn(false)
  }

  const resetHandler = () => {
    setKey(prevKey => prevKey + 1)
    setTimerOn(false)
  }

  let myColors = []
  const myColorsTime = []
  if (agendaItem.timeRed) {
    // console.log(agendaItem.timeRed)
    if (agendaItem.timeGreen) {
      const myGreenTime = ((parseFloat(agendaItem.timeGreen.split(":")[0]) * 60) + parseFloat(agendaItem.timeGreen.split(":")[1]))
      const myAmberTime = ((parseFloat(agendaItem.timeAmber.split(":")[0]) * 60) + parseFloat(agendaItem.timeAmber.split(":")[1]))

      //Blue Color
      myColors.push("#42b0f5");
      myColors.push("#42b0f5");
      myColorsTime.push(totalDuration);
      myColorsTime.push(totalDuration - myGreenTime);

      //Green Color
      myColors.push("#2bb32b");
      myColors.push("#2bb32b");
      myColorsTime.push(totalDuration - myGreenTime);
      myColorsTime.push(totalDuration - myAmberTime);

      //Amber Color
      myColors.push("#eeee60");
      myColors.push("#eeee60");
      myColorsTime.push(totalDuration - myAmberTime);
      myColorsTime.push(30);


      //Red Color
      myColors.push("#ff4a2e");
      myColors.push("#ff4a2e");
      myColorsTime.push(30);
      myColorsTime.push(0);

      // console.log('MyColors: '+myColors)
      // console.log('myColorsTime: '+ myColorsTime)
    }
    else {
      //Blue Color
      const totalDuration = parseFloat(agendaItem.timeRed.split(":")[0]) * 60 + parseFloat(agendaItem.timeRed.split(":")[1]) + 30;

      myColors.push("#42b0f5");
      myColors.push("#42b0f5");
      myColorsTime.push(totalDuration);
      myColorsTime.push(30)
      //Red Color

      myColors.push("#ff4a2e");
      myColors.push("#ff4a2e");
      myColorsTime.push(30);
      myColorsTime.push(0);

      // console.log('MyColors: '+myColors)
      // console.log('myColorsTime: '+ myColorsTime)
    }
  }

  return (
    <>
      {(agendaItem.timeRed) &&
        <>
          <Row className="timer mb-3">
            <Col className="clock" xs={12} lg={12}>
              <CountdownCircleTimer
                className=""
                key={key}
                isPlaying={timerOn}
                duration={parseInt(totalDuration)}
                // duration={40}
                size={400}
                strokeWidth={150}
                trailColor={"#363537"}
                colors={myColors}
                // colors={["#42b0f5","#42b0f5","#ff4a2e","#ff4a2e"]}
                colorsTime={myColorsTime}
                // colorsTime = {[40,30,30,0]}
                onComplete={() => [false, setTimerOn(false)]}

              >
                {renderTime}
              </CountdownCircleTimer>
            </Col>
          </Row>
          <Row>
            <Col>
              {timerOn ? <Button onClick={pauseHandler} >Pause</Button> : <Button onClick={startHandler} >Start</Button>}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {timerOn ? <Button onClick={stopHandler}>Stop</Button> : <Button onClick={resetHandler} variant='danger'>Reset</Button>}
            </Col>
          </Row>
        </>
      }
    </>
  )
}