import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useSelector } from "react-redux"
import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'

import { fetchWrapper } from "_helpers/fetch-wrapper"
import MeetingAddNewModal from './crudModals/MeetingAddNewModal'
import MeetingsCardBig from './MeetingsCardBig'
import MeetingsCard from './MeetingsCard'

export default function MeetingsComponent() {

    const { user: authUser } = useSelector(x => x.auth)

    const url = process.env.REACT_APP_URL

    const [isLoading, setIsLoading] = useState(true, [])
    const [meetings, setMeetings] = useState(true, [])
    const getMeetings = async () => {
        setIsLoading(true)
        const response = await fetchWrapper.get(`${url}/meetings`)
        setMeetings(response)
        setIsLoading(false)
    }

    useEffect(() => {
        getMeetings()
        if (getMeetings.length) getMeetings();
        // eslint-disable-next-line
    }, [])

    if (isLoading) {
        return (
            <section className="meetings" id="meetings">
                <Spinner animation="border" variant="danger" />
            </section>
        )
    }
    else {
        return (
            <section className="meetings" id="meetings">

                <Container>
                    <Row className='header'>
                        <Col xs={12} lg={8} className='title'><h3>All Meetings</h3></Col>
                        {authUser &&
                            <Col xs={12} lg={4} key={'newMeeting'} >
                                <MeetingAddNewModal getMeetings={getMeetings} />
                            </Col>
                        }
                    </Row>

                    <Row>
                        {(meetings.length > 0)
                            ?
                            <>
                                <MeetingsCardBig meeting={meetings[0]} getMeetings={getMeetings} key={meetings[0]._id} />
                                {
                                    (meetings.length > 1)
                                    &&
                                    <>
                                        {meetings.slice(1).map((meeting) => (<MeetingsCard meeting={meeting} getMeetings={getMeetings} key={meeting._id} />))}
                                    </>
                                }
                            </>
                            : <><p>No meetings created yet.</p> {authUser && <><p>How about creating your first meeting?</p><MeetingAddNewModal getMeetings={getMeetings} /></>}</>
                        }
                    </Row>
                </Container>

            </section>
        )
    }

}