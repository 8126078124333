import { Card, Row, Col, Container, Button } from "react-bootstrap";

export default function PasswordReset() {
  return (
    <Card data-bs-theme={'dark'} className="mt-3 mb-3">
      <Card.Header>
        <Row className="mt-3 mb-3"><Col>Sign-in Method</Col></Row>
      </Card.Header>
      <Card.Body>
        <Container>
          <Row className="mt-2 mb-3">
            <Col>
              <Row className="mt-3 mb-3">
                <Col xs={8} lg={10}>
                  <p>Email account: <span>test@tester.com</span></p>

                </Col>
                <Col className="text-end">
                  <Button variant="secondary">Change email</Button>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={8} lg={10}>
                  <p>Password: <span>************</span></p>
                </Col>
                <Col className="text-end">
                  <Button variant="primary">Change password</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card >
  )
}