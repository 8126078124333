import { Col, Row } from "react-bootstrap";
import Feed from "./overview/Feed";
import Roles from "./Roles";


export default function Overview() {
  return (
    <Row>
      <Col xs={12} lg={6}>
        <Feed />
      </Col>
      <Col>
        <Roles />
      </Col>
    </Row>
  )
}