import { Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import { ArrowRightCircle } from 'react-bootstrap-icons'
import headerImg from '../assets/logos/BTMlogo.png'
import 'animate.css';
import { NavBar } from "../components/general/NavBar"
import { Footer } from "components/general/Footer";

export default function NotFoundPage() {

    return (
        <>
            <NavBar pagename="404" />
            <section className="banner" id="home">
                <Container>
                    <Row className="align-items-center">
                        <Col xs={12} md={6} xl={5} className="mb-60" >
                            <div className="animate__animated animate__zoomIn">
                                <img src={headerImg} alt="Header Img" />
                            </div>
                        </Col>
                        <Col xs={12} md={6} xl={7}>
                            <div className="animate__animated animate__fadeIn">
                                <h1 className="animate__animated animate__slideInUp"><span className="bold">WE ARE SORRY</span></h1>
                                <p><br /><br /></p>
                                <p className="border-top-bottom subtitle mb-30 mt-30 animate__animated animate__slideInUp">
                                    We cannot find the page you're looking for
                                    <br /><br />
                                </p>

                                <Link to='/' className="mb-30 mt-30 animate__animated animate__slideInUp">Let's go home <ArrowRightCircle size={25} /></Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    )
}