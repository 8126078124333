import { Container, Row, Col, Card } from 'react-bootstrap'
import { ArrowRightCircle } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'

import { fetchWrapper } from '_helpers/fetch-wrapper'


export const NextMeeting = () => {

  const [isLoading, setIsLoading] = useState(true, [])
  const [meeting, setMeeting] = useState(true, [])

  const url = process.env.REACT_APP_URL

  const getMeetings = async () => {
    setIsLoading(true)

    const response = await fetchWrapper.get(`${url}/meetings/last`)
    setMeeting(response)
    setIsLoading(false)
  }
  const noBgImage = process.env.REACT_APP_NO_BG_IMAGE

  const backgroundImage = (meeting.imageUrl === '' || meeting.imageUrl === 'undefined' || !meeting.imageUrl) ? noBgImage : meeting.imageUrl
  // const backgroundImage = (meeting.imageUrl !== '') ? meeting.imageUrl : "imageNotSet.png"

  useEffect(() => {
    getMeetings()
    // eslint-disable-next-line
  }, [])

  return (
    <section className="nextMeeting" id="nextMeeting">
      <Container>
        {/* <Row className='align-items-center'>
          <Col xs={12} md={12} xl={12} style={{ "backgroundColor": "red", "marginTop": "-80px", "marginBottom": "80px", "padding": "12px", "text-align": "center" }}>
          <Col xs={12} md={12} xl={12} style={{ "backgroundColor": "red", "marginTop": "-80px", "marginBottom": "80px", "padding": "12px", "textAlign": "center" }}>
            <h3>The meeting on 7th May 2024 has been postponed due to holidays to 14th May 2024!</h3>
          </Col>
        </Row> */}
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={6}>
            <div>
              <h2>Join our Next Meeting</h2>
              <p>
                Be our Guest!<br />
                Overcome the fear of public speaking!<br />
                Get a role!<br />
                Grow your leadership skills!
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} xl={6}>
            {(meeting) &&
              <Card className="text-center">
                {
                  (isLoading)
                    ?
                    <Spinner animation="border" variant="danger" />
                    :
                    <Card.Img variant="top" src={backgroundImage} alt="cardImage1" />}
                <div className='nextMeeting-overlay'>
                  {/* <h4>Spring - time of new beginning</h4> */}
                  {/* <p>
                  Mother nature has offered us four seasons in Czech Republic. Considering that the Christian Easter is coming, you're welcome to share with us your opinions regarding the Rite of Spring.
                </p> */}
                </div>
                {/* {(meetings.length > 0) && meetings[0]._id} */}
                {(meeting) && <Link to={"/meeting/" + meeting._id} className='stretched-link'>Open the meeting agenda <ArrowRightCircle size={25} /></Link>}

              </Card>
            }
          </Col>
        </Row>
      </Container>
    </section>
  )
}