import { Card, Col, Row, Modal, Button } from 'react-bootstrap'
import { useState } from 'react'

import infoImage from '../../../assets/backgrounds/goldenCardWebTools.png'


export const Webtools = () => {
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  return (
    <Col xs={12} md={6} xl={4}>
      <Card className="info-cardbx text-center" variant="dark">
        <Card.Img variant="top" src={infoImage} alt="Webtools Card" />
        <div className='info-txtx'>
          <h4>Web-Tools</h4>
          <span></span><br /><br />
          <Button onClick={handleShowModal}>Read more</Button>

        </div>
      </Card>
      <Modal show={showModal} onHide={handleCloseModal} data-bs-theme="dark" centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Web-Tools</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className='mt-5'>
              <h2>Meeting Timer</h2>
              <p className='mb-3'>A perfect webtools to handle timekeeping both for regular meetings as well as contests being able to hide or show the time clock, and get reports with timed speeches.</p>
              <Button className='mb-4 learn-more' href="https://tm-timer.ddns.net/" target='_blank' rel="noreferrer">Toastmasters TIMER</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Contestants Randomizer</h2>
              <p className='mb-3'>A simple webtools to randomize the order of a list of contestants - for a contest.</p>
              <Button className='mb-4 learn-more' href="/tm-tools/contest-randomizer" target='_blank' rel="noreferrer">Contestants Randomizer</Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Col>
  )
}