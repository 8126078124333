import { NavBar } from "components/general/NavBar"
import { Footer } from "components/general/Footer"
import { Container } from 'react-bootstrap'
import Randomizer from "components/ContestRandomizer/Randomizer"

export default function ContestRandomizer() {

  // console.log(meeting.length)

  return (
    <>
      <NavBar pagename="ContestRandomizer" />
      <section className="meeting">
        <Container>
          <Randomizer />
        </Container>
      </section>

      <Footer />
    </>
  )
}