import { NavBar } from "../components/general/NavBar"
import { Banner } from "../components/FrontPage/Banner"
import { NextMeeting } from "../components/FrontPage/NextMeeting"
import { ForYou } from "../components/FrontPage/ForYou"
import { Info } from '../components/FrontPage/Info'
import { Contact } from '../components/FrontPage/Contact';
import { Footer } from "../components/general/Footer";
// import { NavBarOffcanvas } from "components/general/NavBarOffcanvas"



export default function Home() {
    return (
        <>
            <NavBar pagename="FrontPage" />
            <Banner />
            <NextMeeting />
            <ForYou />
            <Info />
            <Contact />
            <Footer />
        </>
    )
}