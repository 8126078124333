import { Card, Col, Row, Modal, Button } from 'react-bootstrap'
import { useState } from 'react'
import infoImage from '../../../assets/backgrounds/goldenCardMeetingRoles.png'

export const MeetingRoles = () => {
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  return (
    <Col xs={12} md={6} xl={4}>
      <Card className="info-cardbx text-center">
        <Card.Img variant="top" src={infoImage} alt="infoImage" />
        <div className='info-txtx'>
          <h4>Club Meeting Roles</h4>
          <span>Understand the meeting structure</span><br /><br />
          <Button onClick={handleShowModal}>Read more</Button>
        </div>
      </Card>
      <Modal show={showModal} onHide={handleCloseModal} data-bs-theme="dark" centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Club Meeting Roles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='mt-5 mb-5'>
            <Col>
              <h2>Club Meeting Roles</h2>
              <p>The success of a club meeting depends on the participants. At each meeting, there are many roles to fill and they play an important part in making the club experience enjoyable.</p>
              <Button className='learn-more' href='https://www.toastmasters.org/membership/club-meeting-roles' target='_blank' rel="noreferrer">Learn More</Button>
              {/* <a href='https://www.toastmasters.org/membership/club-meeting-roles' target='_blank' rel="noreferrer" className="services-link">Learn More</a> */}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Col>
  )
}