import { Card, Row, Col, Container, Form, Button, Image } from "react-bootstrap";
import blondeAvatar from '../../../assets/avatarblonde.png'

export default function Post() {
  return (
    <Card data-bs-theme={'dark'} className="mt-3 mb-3">
      <Card.Header>
        <Row className="mt-3 mb-3">
          <Col className="" xs={2} lg={1}><Image src={blondeAvatar} style={{ width: '45px' }} /></Col>
          <Col><p>User Name</p></Col>
          <Col xs={2} lg={1}></Col>
        </Row>
      </Card.Header>
      <Form>
        <Container>
          <Row className="mt-1 mb-1">
            <Col>
              <p>
                Lorem ipsum odor amet, consectetuer adipiscing elit. Nascetur interdum neque sociosqu cursus ad mauris parturient rhoncus blandit. Laoreet rhoncus cubilia integer donec felis lobortis eget. Et dignissim iaculis tempus molestie aptent. Aenean proin dis torquent mauris habitasse venenatis. Fringilla etiam non purus, sodales litora tellus. Ligula sed purus gravida ipsum orci placerat nullam.
              </p>
            </Col>
          </Row>
          <Row className="mt-1 mb-1">
            <Col>
              <Form.Control as="textarea"
                className='formInput'
                aria-label='Comment...'
                name='comment'
                rows={2}
                placeholder="Comment..."

              />
            </Col>
          </Row>
        </Container>
      </Form>
      <Card.Footer>
        <Row className="mt-3 mb-3">
          <Col className="text-end"><Button variant="primary">Send</Button></Col>
        </Row>
      </Card.Footer>
    </Card>
  )
}