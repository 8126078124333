import { Card, Col, Row, Modal, Button } from 'react-bootstrap'
import { useState } from 'react'
import infoImage from '../../../assets/backgrounds/goldenCardCOT.png'

export const Cot = () => {
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  return (
    <Col xs={12} md={6} xl={4}>
      <Card className="info-cardbx text-center">
        <Card.Img variant="top" src={infoImage} alt="infoImage5" />
        <div className='info-txtx'>
          <h4>Club Officers Training</h4>
          <span>Learn about leadership</span><br /><br />
          <Button onClick={handleShowModal}>Read more</Button>
        </div>
      </Card>
      <Modal show={showModal} onHide={handleCloseModal} data-bs-theme="dark" centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Club Officer Training</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='mb-5'>
            <Col>
              <h2 className='mt-3 mb-3'>Club Officer Training</h2>
              <p className='mb-3'>Club officers are best served by consistent, professional, and engaging training that supports the brand, enhances member experience, and helps them grow as leaders. The materials provided by Toastmasters International are the standard of quality and consistency that should make up the core of District-sponsored Club Officer Training.</p>
              <h2 className='mt-3 mb-3'>New Session Materials</h2>
              <p className='mb-3'>Training should be time well spent for everyone. Make the most of your training time with ready-to-use training sessions that follows a well-structured format and delivers engaging training to club officers. Logically structured guides help facilitators lead sessions that promote vital learning points to club officers and save hours in research and development.</p>
              <Button className='learn-more' href='https://www.toastmasters.org/leadership-central/district-leader-tools/training/club-officer-training-materials' target='_blank' rel="noreferrer">Learn More</Button>
              {/* <a href='https://www.toastmasters.org/leadership-central/district-leader-tools/training/club-officer-training-materials' target='_blank' rel="noreferrer" className="services-link mt-3">Learn More</a> */}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Col>
  )
}