import { Tabs, Tab } from 'react-bootstrap'
import './profile.css'
import Overview from './Overview'
import Settings from './Settings'
import Pathways from './Pathways'

export default function Menu() {

  return (
    <Tabs
      defaultActiveKey="home"
      className='mb-3'
    >
      <Tab eventKey="home" title="Overview" >
        <Overview />
      </Tab>
      <Tab eventKey="pathways" title="Pathways" >
        <Pathways />
      </Tab>
      <Tab eventKey="settings" title="Settings" >
        <Settings />
      </Tab>
    </Tabs>
  )

}