import { NavBar } from '../components/general/NavBar'
import { Header } from 'components/TandC/Header'
import { Content } from 'components/TandC/Content'
import { Footer } from 'components/general/Footer'

export default function TandC() {

    return (
        <>
            <NavBar pagename="TandC"/>
            <Header />
            <Content />
            <Footer />
        </>
    )
}