import { Card, Col, Row, Modal, Button } from 'react-bootstrap'
import { useState } from 'react'
import infoImage from '../../../assets/backgrounds/goldenCardPathways.png'

export const Education = () => {
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  return (
    <Col xs={12} md={6} xl={4}>
      <Card className="info-cardbx text-center" variant="dark">
        <Card.Img variant="top" src={infoImage} alt="infoImage2" />
        <div className='info-txtx'>
          <h4>Education and Pathways</h4>
          <span>Choose your own path</span><br /><br />
          <Button onClick={handleShowModal}>Read more</Button>
        </div>
      </Card>
      <Modal show={showModal} onHide={handleCloseModal} data-bs-theme="dark" centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Education & Pathways</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='mt-3 mb-5'>
            <Col lg={4} s={12}>
              <iframe title='youtube' width={'100%'} src="https://www.youtube.com/embed/iv9cKPn0Wpo?autoplay=0&enablejsapi=1" className='mt-5' />
            </Col>
            <Col>
              <h2 className='mt-3 mb-3'>Education</h2>
              <p>Education is an important part of every Toastmaster's journey, and it is a critical aspect of all club meetings around the world. Participating in Toastmasters' education programs allows you to gain the valuable practice needed to develop your public speaking, communication, and leadership skills. The safe and supportive environment clubs provide allows you to learn at your own pace in a curriculum that has helped millions meet their personal and professional goals.</p>
              <Button className='learn-more' href='https://www.toastmasters.org/education' target='_blank' rel="noreferrer">Learn More</Button>
              {/* <a href='https://www.toastmasters.org/education' target='_blank' rel="noreferrer" className="services-link">Learn More</a> */}
            </Col>
          </Row>
          <Row className='mt-3 mb-5'>
            <Col>
              <h2>Toastmasters - The navigator</h2>
              <p> Each day, thousands of people go to Toastmasters meetings held in communities and organizations around the globe. A club meeting isn't structured like a classroom with an instructor. Instead, it's a supportive group environment where people with diverse skill sets and backgrounds come together to practice their skills and exchange valuable feedback with each other</p>
              <Button className='learn-more' href='https://toastmasterscdn.azureedge.net/medias/files/the-navigator/8722-the-navigator.pdf' target='_blank' rel="noreferrer">Learn More</Button>
              {/* <a href='https://www.toastmasters.org/pathways-overview' target='_blank' rel="noreferrer" className="services-link">Learn More</a> */}
            </Col>
          </Row>

          <Row className='mt-3 mb-5'>
            <Col>
              <h2>What are Pathways</h2>
              <p>Toastmasters Pathways learning experience, an exciting, flexible and interactive way to develop your skills and help others in your club develop theirs. Pathways helps you learn communication and leadership skills that you need to succeed.</p>
              <Button className='learn-more' href='https://www.toastmasters.org/pathways-overview' target='_blank' rel="noreferrer">Learn More</Button>
              {/* <a href='https://www.toastmasters.org/pathways-overview' target='_blank' rel="noreferrer" className="services-link">Learn More</a> */}
            </Col>
          </Row>
          <Row className='mt-3 mb-5'>
            <Col>
              <h2>Toastmasters Pathways - paths and core competencies</h2>
              <p> The Toastmasters Pathways learning experience was developed around the five core competencies identified by the Board of Directors.</p>
              <Button className='learn-more' href='https://ccdn.toastmasters.org/medias/files/pathways/8077-pathways-path-and-core-competencies/8077-pathways-paths-and-core-competencies_2022.pdf' target='_blank' rel="noreferrer">Learn More</Button>
              {/* <a href='https://www.toastmasters.org/pathways-overview' target='_blank' rel="noreferrer" className="services-link">Learn More</a> */}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Col>
  )
}