import Role from "./overview/Roles";


export default function Roles() {
  return (
    <>
      <Role />
      <Role />
    </>
  )
}