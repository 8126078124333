import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dropdown from 'react-bootstrap/Dropdown'
import navIcon2 from "../../assets/img/nav-icon2.svg";
import youTube from '../../assets/svg/brand-logos/icons8-youtube-logo-100.svg';

import { Link } from 'react-router-dom'
import { useSelector } from "react-redux"


import MeetingInfoModal from './crudModals/MeetingInfoModal'
import MeetingEditModal from './crudModals/MeetingEditModal'
import MeetingDeleteModal from './crudModals/MeetingDeleteModal'

export default function MeetingsCard({ meeting, getMeetings }) {

  const { user: authUser } = useSelector(x => x.auth)

  const noBgImage = process.env.REACT_APP_NO_BG_IMAGE

  const backgroundImage = (meeting.imageUrl === '' || meeting.imageUrl === 'undefined' || !meeting.imageUrl) ? noBgImage : meeting.imageUrl
  const meetingTitle = (meeting.imageUrl === '' || meeting.imageUrl === 'undefined' || !meeting.imageUrl) ? meeting.meetingTheme : ''

  return (
    <Col xs={12} lg={12}>
      <Card className='my-2 text-center semi-transparent text-white bg-dark bigCard' >
        <Card.Title className='my-2'>
          <Row>
            {/* <Col style={{ height: '21px' }}></Col> */}
            <Col xs={12} lg={12} className='float-end'>
              {authUser &&
                <Dropdown drop='start' data-bs-theme="dark" className='float-end'>
                  <Dropdown.Toggle variant="dark">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis-vertical" className="svg-inline--fa fa-ellipsis-vertical " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" width="6" height="16"><path fill="currentColor" d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"></path></svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <MeetingEditModal meeting={meeting} getMeetings={getMeetings} />
                    <Dropdown.Divider />
                    <MeetingDeleteModal meeting={meeting} getMeetings={getMeetings} />
                  </Dropdown.Menu>
                </Dropdown>
              }
            </Col>
          </Row>
        </Card.Title>
        <Card.Body className='to-left'>
          <Row>
            <Col xs={12} lg={6}>
              <Card.Img variant="left" src={backgroundImage} alt="cardImage1" style={{ marginTop: "-52px" }} />
              <h3 className='bigCard not-found-title'>{meetingTitle}</h3>
            </Col>
            <Col>
              <Row>
                <Col>
                  <h3>{meeting.meetingTheme}</h3>
                  <p>{meeting.meetingType} Meeting <span>{(meeting.meetingNumber !== null) && '#' + meeting.meetingNumber}</span></p>
                  <p>{meeting.meetingDate}</p>
                  <h5>{meeting.meetingDescription !== '' && <><MeetingInfoModal meeting={meeting} />{meeting.meetingDescription}</>} </h5>
                  <br />
                  <Link to={"/meeting/" + meeting._id} className='btn btn-primary' >See the Agenda</Link>
                </Col>
              </Row>
              <Row className='to-left'>
                <Col className='social-icon mt-5'>
                  {(meeting.facebookUrl !== '' && meeting.facebookUrl) ? <a href={meeting.facebookUrl} target='_blank' rel="noreferrer"><img src={navIcon2} alt="Facebook Icon Event" /></a> : ''}
                  {(meeting.youtubeUrl !== '' && meeting.youtubeUrl) ? <a href={meeting.youtubeUrl} target='_blank' rel="noreferrer"><img src={youTube} alt="Youtube Icon Event" /></a> : ''}
                </Col>
              </Row>
            </Col>

          </Row>
        </Card.Body>
      </Card>
    </Col >
  )
}