import { Card, Col, Row, Modal, Button, Image } from 'react-bootstrap'
import { useState } from 'react'
import infoImage from '../../../assets/backgrounds/goldenCardAboutUs.png'
import goldenCard from '../../../assets/backgrounds/goldenCard.png'

export const AboutUs = () => {
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  return (
    <Col xs={12} md={6} xl={4}>
      <Card className="info-cardbx text-center">
        <Card.Img variant="top" src={infoImage} alt="About Us" />
        <div className='info-txtx'>
          <h4>About us</h4>
          <span>History & Details</span><br /><br />
          <Button onClick={handleShowModal}>Read more</Button>
        </div>
      </Card>
      <Modal show={showModal} onHide={handleCloseModal} data-bs-theme="dark" centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>About Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={0} lg={2}></Col>
            <Col xs={12} lg={8}>
              <Image className='mx-auto mt-5' src={goldenCard} width={500} />
            </Col>
            <Col xs={0} lg={2}></Col>
          </Row>
          <Row>
            <Col>
              <h2 className='mt-3 mb-3'>About Us</h2>
              <p>
                Brno Toastmasters was by a group of enthusiasts with a passion for communication and personal growth. Since the beginning, the club's meetings offered a supportive atmosphere and valuable feedback to people who wanted to overcome the fear of speaking in public or looked to embark on a new leadership challenge.
                <br />
                Our mission is to develop the new leaders of tomorrow.
              </p>
              <p>
                In 2008, the club IBM Brno Toastmasters was established, as a company club under Brno branch of IBM.
                <br />
                In April 2009, the club was renamed to Brno Toastmasters and opened membership to public.
              </p>
              <br /><br />
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Brno Toastmasters</h2>
              <p>
                Club Number 1125152
                <br />
                Area 1
                <br />
                Division C
                <br />
                District 110
              </p>
              <p>
                Brno Toastmasters z.s. is a non-profit organization registered under the IČO 22855599
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Col>
  )
}