
import { Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { NavDropdown } from "react-bootstrap"
import { authActions } from '_store'

export const Signout = () => {
  const dispatch = useDispatch()

  const { user: authUser } = useSelector(x => x.auth)

  const signout = (e) => {
    e.preventDefault()
    dispatch(authActions.logout())
  }

  return (
    <NavDropdown id='basic-navbar-nav' title={<Image src={'data:image/png;base64, ' + authUser.user.avatar} style={{ width: '40px', height: '40px' }} />} >

      <NavDropdown.Item as={Link} to='/profile'>Profile</NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item onClick={signout} >Signout</NavDropdown.Item>
    </NavDropdown>
  )
}