import { Card, Row, Col, Container, Form, Button, Image } from "react-bootstrap";
import meetingImage from '../../../assets/backgrounds/imageNotSet.png'

export default function Role() {
  return (
    <Card data-bs-theme={'dark'} className="mt-3 mb-3">
      <Card.Header>
        <Row className="mt-3 mb-3">
          <Col xs={8} lg={9}><p>Meeting Role</p></Col>
          <Col xs={4} lg={3}><p className="text-end">dd/mm/yyyy</p></Col>
        </Row>
      </Card.Header>
      <Form>
        <Container>
          <Row className="mt-1 mb-1">
            <Col xs={4}>
              <Row className="mt-2 mb-2">
                <Col>Meeting Theme</Col>
              </Row>
              <Row className="mt-1 mb-1">
                <Col>
                  <Image src={meetingImage} />
                </Col>
              </Row>
            </Col>
            <Col>
              <p>
                Lorem ipsum odor amet, consectetuer adipiscing elit. Nascetur interdum neque sociosqu cursus ad mauris parturient rhoncus blandit. Laoreet rhoncus cubilia integer donec felis lobortis eget. Et dignissim iaculis tempus molestie aptent. Aenean proin dis torquent mauris habitasse venenatis. Fringilla etiam non purus, sodales litora tellus. Ligula sed purus gravida ipsum orci placerat nullam.
              </p>
            </Col>
          </Row>
        </Container>
      </Form>
      <Card.Footer>
        <Row className="mt-3 mb-3">
          <Col className="text-end"><Button variant="danger">Reject</Button>  <Button variant="primary">Accept</Button></Col>
        </Row>
      </Card.Footer>
    </Card>
  )
}