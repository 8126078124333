//TODO
// fix reload when agenda.length becomes back to 0 elements

import { Row, Col, Button } from 'react-bootstrap'
import { useSelector } from "react-redux"
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import AgendaItemCard from './AgendaItemCard'
import AgendaItemAddNewModal from './crudModals/AgendaItemAddNewModal'
import { fetchWrapper } from '_helpers/fetch-wrapper'
import { Spinner } from 'react-bootstrap'
import noSleep from 'nosleep.js'

export default function AgendaComponent() {

  const { user: authUser } = useSelector(x => x.auth)
  const params = useParams()
  const url = process.env.REACT_APP_URL
  const meetingId = params.meetingId

  let myNoSleep = new noSleep()

  const [isLoading, setIsLoading] = useState(true, [])
  const [agenda, setAgenda] = useState([])
  const [isNoSleep, setIsNoSleep] = useState(false)
  const [toggleText, setToggleText] = useState('Keep Screen On')
  const [toggleColor, setToggleColor] = useState('primary')

  const getAgenda = async () => {
    setIsLoading(true)
    const response = await fetchWrapper.get(`${url}/agendaItems/${meetingId}`)
    if (!response.Error) {
      setAgenda(response)
    }
    else {
      // setResponseError(response.Error)
    }
    setIsLoading(false)
  }
  useEffect(() => {
    getAgenda()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    // getAgenda()
    if (isNoSleep) { myNoSleep.enable() } else { myNoSleep.disable(); }
    console.log('toggleWakeOnScreen: ' + isNoSleep)
    // eslint-disable-next-line
  }, [isNoSleep])


  const toggleWakeOnScreen = (e) => {
    e.preventDefault()
    // console.log('toggleWakeOnScreen: ' + isNoSleep)
    setIsNoSleep(!isNoSleep)
    isNoSleep ? setToggleText('Keep Screen On') : setToggleText('Allow screen off')
    isNoSleep ? setToggleColor('primary') : setToggleColor('danger')
    // isNoSleep ? noSleep.enable() : noSleep.disable()
    if (toggleColor === 'danger') { window.location.reload(false); }
  }




  return (
    <section className='agenda' >
      {
        isLoading ?
          <Spinner animation='border' variant='danger' />
          :
          <>
            {
              (agenda.length === 0) ?
                <Row>
                  <Col>
                    <h2>There are no items in this agenda</h2>
                    {authUser &&
                      <AgendaItemAddNewModal meetingId={meetingId} getAgenda={getAgenda} />
                    }
                  </Col>
                </Row>
                :
                <>
                  <Row>
                    <Col>
                      <Button variant={toggleColor} onClick={toggleWakeOnScreen}>{toggleText} {isNoSleep}</Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {authUser &&
                        <AgendaItemAddNewModal meetingId={meetingId} getAgenda={getAgenda} />
                      }
                      {
                        agenda.map((agendaItem) => (
                          <div key={agendaItem._id}>
                            <AgendaItemCard agendaItem={agendaItem} getAgenda={getAgenda} />
                            {authUser &&
                              <AgendaItemAddNewModal meetingId={meetingId} getAgenda={getAgenda} prevPos={agendaItem} />
                            }
                          </div>
                        ))}
                    </Col>
                  </Row>
                </>
            }
          </>
      }
    </section>
  )
}