import { Card, Row, Col, Container, Form, Button, Image } from "react-bootstrap";
import blondeAvatar from '../../../assets/avatarblonde.png'

export default function NewPost() {
  return (
    <Card data-bs-theme={'dark'} className="mt-3 mb-3">
      <Card.Header>
        <Row className="mt-3 mb-3">
          <Col className="" xs={2} lg={1}><Image src={blondeAvatar} style={{ width: '45px' }} /></Col>
          <Col><p>User Name</p></Col>
          <Col xs={2} lg={1}></Col>
        </Row>
      </Card.Header>
      <Form>
        <Container>
          <Row className="mt-1 mb-1">
            <Col>
              <Form.Control as="textarea"
                className='formInput'
                aria-label='What is on your mind?'
                name='post'
                rows={3}
                placeholder="What is on your mind?"

              />
            </Col>
          </Row>
        </Container>
      </Form>
      <Card.Footer>
        <Row className="mt-3 mb-3">
          <Col className="text-end"><Button variant="primary">Send</Button></Col>
        </Row>
      </Card.Footer>
    </Card>
  )
}