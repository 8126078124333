import { useEffect, useState } from "react";
import { Card, Row, Col, Form, Container, Button } from "react-bootstrap";
// import { useSelector } from "react-redux";

export default function UserDetails(profileUser) {

  // const { user: authUser } = useSelector(x => x.auth)

  // const url = process.env.REACT_APP_URL

  console.log(profileUser.profileUser.firstName)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNo, setPhoneNo] = useState('')
  const [website, setWebsite] = useState('')

  useEffect(function () {
    !profileUser.profileUser.firstName ? setFirstName('') : setFirstName(profileUser.profileUser.firstName)
    !profileUser.profileUser.lastName ? setLastName('') : setLastName(profileUser.profileUser.lastName)
    !profileUser.profileUser.phoneNo ? setPhoneNo('') : setPhoneNo(profileUser.profileUser.phoneNo)
    !profileUser.profileUser.website ? setWebsite('') : setWebsite(profileUser.profileUser.website)
  }, [profileUser.profileUser.firstName, profileUser.profileUser.lastName, profileUser.profileUser.phoneNo, profileUser.profileUser.website])

  return (
    <Card data-bs-theme={'dark'} className="mt-3 mb-3">
      <Form>
        <Card.Header>
          <Row className="mt-3 mb-3"><Col>Profile Details</Col></Row>
        </Card.Header>
        <Card.Body>
          <Container>
            <Row className="mt-2 mb-3">
              <Col>
                <Row className="mt-3 mb-3">
                  <Col xs={4} lg={2}>
                    <p>First Name<span >*</span></p>
                  </Col>
                  <Col>
                    <Form.Control
                      className="formInput"
                      placeholder="First Name*"
                      aria-label="First Name*"
                      name="fname"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={4} lg={2}>
                    <p>Last Name<span>*</span></p>
                  </Col>
                  <Col>
                    <Form.Control
                      className="formInput"
                      placeholder="Last Name*"
                      aria-label="Last Name*"
                      name="lname"
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={4} lg={2}>
                    <p>Phone</p>
                  </Col>
                  <Col>
                    <Form.Control
                      className="formInput"
                      placeholder="Phone"
                      aria-label="Phone"
                      name="phone"
                      value={phoneNo}
                      onChange={e => setPhoneNo(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={4} lg={2}>
                    <p>Website</p>
                  </Col>
                  <Col>
                    <Form.Control
                      className="formInput"
                      placeholder="https://yourwebsite.com"
                      aria-label="Website"
                      name="website"
                      value={website}
                      onChange={e => setWebsite(e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Card.Body>
        <Card.Footer>
          <Row className="mt-3 mb-3">
            <Col className="text-end"><Button variant="secondary">Discard</Button> <Button variant="primary">Save Changes</Button></Col>
          </Row>
        </Card.Footer>
      </Form>
    </Card >
  )
}